import { Module, MutationTree, ActionTree, GetterTree, Commit, Dispatch } from 'vuex';
import { RootStore } from '@/features/core/store';
import saveProjectDraft from '@/features/app-enterprise-project/views/enterprise-project-create/save-project-draft.gql';
import createBuildings from '@/features/app-enterprise-project/views/enterprise-project-create/create-buildings-mutation.gql';
import Vue from 'vue';
import { DollarApollo } from 'vue-apollo/types/vue-apollo';
import { SaveProjectDraftMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/SaveProjectDraftMutation';
import {
  hasObjectChanged,
  transformDbObjectForState,
  transformStateForSaving,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-helpers';
import {
  DatabaseProjectDraft,
  ProjectDraftCompleted,
  SaveToDBParams,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';
import { CreateBuildingsMutationVariables } from '@/features/app-enterprise-project/views/enterprise-project-create/__generated__/CreateBuildingsMutation';
import {
  EnterpriseProjectObjectUseCase,
  EnterpriseProjectServiceType,
} from '@/features/app-enterprise-project/enterprise-project-constants';

// Define the type for the state
export type NewProjectData = {
  id: string | null;
  projectReference: string | null;
  general: NewProjectGeneralData;
  customerInfo: CustomerInfo;
  buildings: ProjectBuilding[];
};

export type CustomerInfo = {
  id?: string | null;
  customerName: string | null;
  customerNumber: string | null;
  contactPerson: string | null;
  invoiceNumber: string | null;
  contractTerm: number | null;
};

export type NewProjectGeneralData = {
  customerName: string | null;
  customerNumber: string | null;
  partnerName: string | null;
  invoiceNumber: string | null;
  contractTerm: number | null;
};

export type BuildingInformation = {
  title: string;
  firstName: string;
  surname: string;
  phoneNumber: string;
  email: string;
};

export type ProjectBuilding = {
  id: number;
  buildingId: number;
  name: string;
  cityAddress: string;
  houseAddress: number;
  houseAddressSuffix?: string;
  streetAddress: string;
  postCodeAddress: string;
  locationLongitude: number;
  locationLatitude: number;
  numberOfApartments: number;
  wieNumber?: string;
  liegenshaft: boolean;
  liegenshaftInformation?: BuildingInformation;
  // To keep track of the completion of the building information
  liegenshaftInformationCompleted?: boolean;
  parentProperty?: number | null;
  mainService?: EnterpriseProjectServiceType | null;
  products?: LiegenschaftProduct[];
  useCases?: LiegenschaftUseCase[];
  invoiceName?: string | null;
  serviceLevel?: string | null;
  installationStartDate?: string | null;
  preInspection?: boolean | null;
};

export enum EnterpriseProjectObjectProduct {
  INFRASTRUCTURE_VARIANT = 'INFRASTRUCTURE_VARIANT',
  IW_CLOUD = 'IW_CLOUD',
}

export enum InfrastructureVariantType {
  START = 'Start',
  BASIS = 'Basis',
  'BASIS+' = 'Basis+',
  PRO = 'Pro',
}

export enum IWCloudType {
  STANDARD = 'Standard',
  PRO = 'Pro',
}

export interface LiegenschaftProduct {
  monthlyPriceInEuros?: number | null;
  oneTimePriceInEuros?: number | null;
  productType?: EnterpriseProjectObjectProduct;
  option?: InfrastructureVariantType | IWCloudType | null;
}

export interface LiegenschaftUseCase {
  label?: string | null;
  title?: string | null;
  checked?: boolean | null;
  monthlyPriceInEuros?: number | null;
  oneTimePriceInEuros?: number | null;
  useCaseType?: EnterpriseProjectObjectUseCase | null;
}

export interface CreatedBuildingMeta {
  buildingReferenceId: string;
}

export interface EditOptions {
  editableBuildings: Map<string, CreatedBuildingMeta>;
  buildingChangeMode: boolean;
  buildingsCreated: boolean;
  editable: boolean;
  projectDownloaded: boolean;
  numberOfNonEditableBuildings: number;
}

// Define the state type
export type ProjectCreateState = {
  newProjectData: NewProjectData;
  activeStep1BuildingId: number | null;
  activeStep2BuildingId: number;
  tab: 'step1' | 'step2' | 'step3' | 'preview';
  saving: boolean;
  objectToSave?: any;
  generalInformationCompleted: boolean;
  buildingsImportCompleted: boolean;
  propertyInformationCompleted: boolean;
  propertyAssociationCompleted: boolean;
  // Will keep track of which buildings are selected by used the building ids as the index
  selectedBuildingsForContactInformation: boolean[];
  // Show or hide property checkboxes for step 1.3
  displayPropertyInformationCheckboxes: boolean;
  // buildings that are already created are still editable: Map< building id, meta>
  editableBuildings?: Map<string, CreatedBuildingMeta>;
  // Whether the project is being edited in draft mode (false) or there are buildings already created (true)
  buildingChangeMode?: boolean;
  // building reference IDs that need to be invalidated to recreate the buildings
  buildingChangeList?: Set<string>;
  numberOfNonEditableBuildings?: number;
  buildingsCreated?: boolean;
  originalSaveObject?: (DatabaseProjectDraft & ProjectDraftCompleted & EditOptions) | null;
  allLiegenshaftInformationCompleted?: boolean;
  associatedBuildings: number;
};

// Initial state of the project
const state: ProjectCreateState = {
  newProjectData: {
    id: null,
    projectReference: null,
    general: {
      customerName: null,
      customerNumber: null,
      partnerName: null,
      invoiceNumber: null,
      contractTerm: null,
    },
    customerInfo: {
      customerName: null,
      customerNumber: null,
      contactPerson: null,
      invoiceNumber: null,
      contractTerm: null,
    },
    buildings: [],
  },
  activeStep1BuildingId: null,
  activeStep2BuildingId: 0,
  tab: 'step1',
  objectToSave: undefined,
  saving: false,
  // For handling the building selection on step 1.3
  selectedBuildingsForContactInformation: [],
  displayPropertyInformationCheckboxes: false,
  // This is to keep track of what has been completed in the project creation
  generalInformationCompleted: false,
  buildingsImportCompleted: false,
  propertyInformationCompleted: false,
  propertyAssociationCompleted: false,
  editableBuildings: new Map<string, CreatedBuildingMeta>(),
  buildingChangeMode: false,
  buildingChangeList: new Set<string>(),
  buildingsCreated: false,
  originalSaveObject: null,
  allLiegenshaftInformationCompleted: false,
  associatedBuildings: 0,
};

interface SetBuildingInformationPayload {
  buildingInformation: BuildingInformation;
  buildingId: number;
}

const addBuildingToChangeList = (buildingId: string): void => {
  if (!state.buildingChangeMode) {
    return;
  }

  const idKey = `${buildingId}`;

  const buildingMeta = state.editableBuildings?.get(idKey);

  if (buildingMeta) {
    state.buildingChangeList?.add(buildingMeta.buildingReferenceId);
  }
};

const updateBuildingChangeListByIds = (state: ProjectCreateState, buildingIds: number[]): void => {
  for (const buildingId of buildingIds) {
    addBuildingToChangeList(`${buildingId}`);
  }
};

const buildingHasConfigurations = (building: ProjectBuilding): boolean => {
  return (
    !!building.mainService ||
    building.products?.some((product) => product.monthlyPriceInEuros || product.oneTimePriceInEuros) ||
    building.useCases?.some((useCase) => useCase.monthlyPriceInEuros || useCase.oneTimePriceInEuros) ||
    false
  );
};

// Mutations
const mutations: MutationTree<ProjectCreateState> = {
  setTab(state, tab: 'step1' | 'step2' | 'step3' | 'preview') {
    state.tab = tab;
  },
  setSaving(state, saving: boolean) {
    state.saving = saving;
  },
  clearState(state) {
    state.newProjectData = {
      id: null,
      projectReference: null,
      general: {
        customerName: null,
        customerNumber: null,
        partnerName: null,
        invoiceNumber: null,
        contractTerm: null,
      },
      customerInfo: {
        customerName: null,
        customerNumber: null,
        contactPerson: null,
        invoiceNumber: null,
        contractTerm: null,
      },
      buildings: [],
    };
    state.activeStep1BuildingId = null;
    state.activeStep2BuildingId = 0;
    state.tab = 'step1';
    state.objectToSave = undefined;
    state.saving = false;
    state.generalInformationCompleted = false;
    state.buildingsImportCompleted = false;
    state.propertyInformationCompleted = false;
    state.propertyAssociationCompleted = false;
    state.editableBuildings = new Map<string, CreatedBuildingMeta>();
    state.buildingChangeMode = false;
    state.buildingChangeList = new Set<string>();
    state.buildingsCreated = false;
  },
  // Edit mode setup
  setBuildingChangeMode(state, buildingChangeMode: boolean) {
    state.buildingChangeMode = buildingChangeMode;
  },
  setEditableBuildings(state, editableBuildings: Map<string, CreatedBuildingMeta>) {
    state.editableBuildings = editableBuildings;
  },
  setNumberOfNonEditableBuildings(state, numberOfNonEditableBuildings: number) {
    state.numberOfNonEditableBuildings = numberOfNonEditableBuildings;
  },
  setBuildingsCreated(state, buildingsCreated: boolean) {
    state.buildingsCreated = buildingsCreated;
  },
  // Step 1
  setNewProjectData(state, newProjectGeneralData: NewProjectData) {
    state.newProjectData.id = newProjectGeneralData.id;
    state.newProjectData.projectReference = newProjectGeneralData.projectReference;
    state.newProjectData.general = newProjectGeneralData.general;
  },
  setOriginalData(state, originalProjectData: DatabaseProjectDraft & ProjectDraftCompleted & EditOptions) {
    state.originalSaveObject = originalProjectData;
  },
  setNewBuildingsData(state, buildings: ProjectBuilding[]) {
    state.newProjectData.buildings = buildings;
  },
  setActiveStep1BuildingId(state, buildingId: number | null) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (buildingId && state.newProjectData.buildings[i].buildingId === buildingId) {
        // Check if the new active building has the liegenshaftInformation property
        if (!state.newProjectData.buildings[i].liegenshaftInformation) {
          state.newProjectData.buildings[i].liegenshaftInformation = {
            firstName: '',
            surname: '',
            title: '',
            phoneNumber: '',
            email: '',
          };
        }
      }
    }
    state.activeStep1BuildingId = buildingId;
  },
  setBuildingInformationForBuildingId(state, payload: SetBuildingInformationPayload) {
    const { buildingInformation, buildingId } = payload;
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === buildingId) {
        state.newProjectData.buildings[i].liegenshaftInformation = buildingInformation;
        state.newProjectData.buildings[i].liegenshaftInformationCompleted = true;
      }
    }
    // Check if all liegenshaft buildings have information
    const incompleteLiegenschafts = state.newProjectData.buildings.filter(
      (building) => building.liegenshaft && !building.liegenshaftInformationCompleted,
    );
    if (incompleteLiegenschafts.length === 0) {
      state.allLiegenshaftInformationCompleted = true;
    }

    // Clear the selectedBuildingsForContactInformation
    state.selectedBuildingsForContactInformation = [];
  },
  setAllBuildingInformation(state, buildingInformation: BuildingInformation) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].liegenshaft) {
        state.newProjectData.buildings[i].liegenshaftInformation = buildingInformation;
      }
    }
  },
  setGeneralInformationCompleted(state, completed: boolean) {
    state.generalInformationCompleted = completed;
  },
  setBuildingsImportCompleted(state, completed: boolean) {
    state.buildingsImportCompleted = completed;
  },
  setPropertyInformationCompleted(state, completed: boolean) {
    state.propertyInformationCompleted = completed;
  },
  setPropertyAssociationCompleted(state, completed: boolean) {
    state.propertyAssociationCompleted = completed;
  },
  setBuildingSelectionForContactInformation(state, payload: { buildingId: number; selected: boolean }) {
    state.selectedBuildingsForContactInformation[payload.buildingId] = payload.selected;
  },
  clearBuildingSelectionForContactInformation(state) {
    state.selectedBuildingsForContactInformation = [];
  },
  setDisplayPropertyInformationCheckboxes(state, display: boolean) {
    state.displayPropertyInformationCheckboxes = display;
  },
  // Step 2
  setActiveStep2BuildingId(state, id: number) {
    state.activeStep2BuildingId = id;
  },
  saveBuildingAssociations(state, updatedBuilding: ProjectBuilding) {
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === updatedBuilding.buildingId) {
        state.newProjectData.buildings[i].parentProperty = updatedBuilding.parentProperty;
      }
    }
    const buildingsWithParents = state.newProjectData.buildings.filter(
      (building: ProjectBuilding) =>
        !building.liegenshaft && building.parentProperty !== undefined && building.parentProperty !== null,
    );
    state.associatedBuildings = buildingsWithParents.length;
  },
  updateBuildingWIENumber(state, { wieNumber, buildingId }: { wieNumber: string; buildingId: number }) {
    // Loop through the buildings and update the wieNumber
    for (let i = 0; i < state.newProjectData.buildings.length; i++) {
      if (state.newProjectData.buildings[i].buildingId === buildingId) {
        state.newProjectData.buildings[i].wieNumber = wieNumber;
        return;
      }
    }
  },
  // Step 3
  applyActiveLiegenschaftSettingsToAll(
    state,
    payload: {
      mainService: EnterpriseProjectServiceType;
      products: LiegenschaftProduct[];
      useCases: LiegenschaftUseCase[];
    },
  ) {
    const selectedLiegenshafts = state.newProjectData.buildings.filter((building) => building.liegenshaft);
    selectedLiegenshafts.forEach((liegenshaft) => {
      // apply settings to liegenshafts with empty settings
      const hasProducts = liegenshaft.products?.some(
        (product) => product.monthlyPriceInEuros || product.oneTimePriceInEuros,
      );
      const hasUseCases = liegenshaft.useCases?.some(
        (product) => product.monthlyPriceInEuros || product.oneTimePriceInEuros,
      );

      if (!hasProducts && !hasUseCases) {
        liegenshaft.mainService = payload.mainService;
        liegenshaft.products = payload.products;
        liegenshaft.useCases = payload.useCases;
      }
    });
  },
  applyActiveConfigurations(
    state,
    payload: {
      mainService: EnterpriseProjectServiceType;
      buildingIds: number[];
      products: LiegenschaftProduct[];
      useCases: LiegenschaftUseCase[];
    },
  ) {
    const buildings = state.newProjectData.buildings.filter((building) =>
      payload.buildingIds.includes(building.buildingId),
    );
    buildings.forEach((building) => {
      building.mainService = payload.mainService;
      building.products = payload.products;
      building.useCases = payload.useCases;
    });
  },
  updateLiegenschaftProducts(state, payload: { id: number; products: LiegenschaftProduct[] }) {
    const { id, products } = payload;
    const building = state.newProjectData.buildings.find((building) => building.buildingId === id);
    if (building) {
      building.products = products;
    }
  },
};

export interface EnterpriseActions {
  saveNewBuildingData(
    { commit, dispatch }: { commit: Commit; dispatch: Dispatch },
    buildings: ProjectBuilding[],
  ): Promise<void>;
}

// Actions
const actions: ActionTree<ProjectCreateState, EnterpriseActions> = {
  // Saving functions
  async saveToDB({ state, commit, dispatch }, params: SaveToDBParams) {
    commit('setSaving', true);
    // First convert the state data
    const transformedObject = transformStateForSaving(state.newProjectData);
    // Add all the completed state variables to objectToSave
    const objectToSave = {
      ...transformedObject,
      generalInformationCompleted: state.generalInformationCompleted,
      buildingsImportCompleted: state.buildingsImportCompleted,
      propertyInformationCompleted: state.propertyInformationCompleted,
      propertyAssociationCompleted: state.propertyAssociationCompleted,
    };

    const { errors } = await params.apolloClient.mutate({
      mutation: saveProjectDraft,
      variables: {
        input: {
          pageContent: objectToSave,
          pageReference: 'full',
          projectReference: state.newProjectData.projectReference,
        },
      } as SaveProjectDraftMutationVariables,
    });

    if (params.finalSave) {
      const buildingIds: number[] = [];
      if (transformedObject?.mainBuildings) {
        // Check with buildings have changed
        for (let i = 0; i < transformedObject?.mainBuildings.length; i++) {
          // Get the same building in the original object
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const originalBuilding = state.originalSaveObject?.mainBuildings?.find(
            (building) => building.id === transformedObject?.mainBuildings[i].id,
          );
          if (originalBuilding) {
            const changed = hasObjectChanged(originalBuilding, transformedObject?.mainBuildings[i]);
            if (changed) {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              buildingIds.push(originalBuilding.id);
            }
          }
        }
      }
      updateBuildingChangeListByIds(state, buildingIds);
      // First wait a second before calling the final save
      await new Promise((resolve) => setTimeout(resolve, 5000));
      await dispatch('saveToDBFinal', params.apolloClient);
    }

    commit('setSaving', false);
  },
  // Saving functions
  async saveToDBFinal({ state, commit }, apolloClient: DollarApollo<Vue>) {
    commit('setSaving', true);

    let updatedBuildingReferenceIds: string[] | undefined;

    if (state.buildingChangeList && state.buildingChangeList.size > 0) {
      updatedBuildingReferenceIds = Array.from(state.buildingChangeList.values());
    }
    // If there are existing buildings are no buildings to update, we can return
    if (state.editableBuildings && state.editableBuildings.size > 0 && !updatedBuildingReferenceIds) {
      commit('setSaving', false);
      return;
    }
    const { errors } = await apolloClient.mutate({
      mutation: createBuildings,
      variables: {
        input: {
          projectReference: state.newProjectData.projectReference,
          updatedBuildingReferenceIds,
        },
      } as CreateBuildingsMutationVariables,
    });
    commit('setSaving', false);
  },
  async setUpdateTab({ commit, dispatch }, tab: 'step1' | 'step2' | 'step3' | 'preview') {
    if (tab === 'step1') {
      // When going back to step 1, we need to reset the active building index
      state.activeStep1BuildingId = null;
      // We also set the allLiegenshaftInformationCompleted to false on the buildings so that the user can re-enter
      // information if needed.
      state.newProjectData.buildings.forEach((building) => {
        if (building.liegenshaft) {
          building.liegenshaftInformationCompleted = false;
        }
      });
      await dispatch('getNextActiveBuilding');
    }
    if (tab === 'step2' || tab === 'step3') {
      // When going to step 2 or 3 we want to reset the active building to the first
      const firstProperty = state.newProjectData.buildings.find((building) => building.liegenshaft);
      if (firstProperty?.buildingId) {
        commit('setActiveStep2BuildingId', firstProperty?.buildingId);
      }
    }
    commit('setTab', tab);
  },
  async hydrateProjectDraftData(
    { commit, dispatch },
    projectData: DatabaseProjectDraft & ProjectDraftCompleted & EditOptions,
  ) {
    // First convert the state data
    const transformedData = transformDbObjectForState(projectData);
    commit('setOriginalData', projectData);
    commit('setNewProjectData', transformedData);
    commit('setNewBuildingsData', transformedData.buildings);
    commit('setEditableBuildings', projectData.editableBuildings);
    commit('setBuildingChangeMode', projectData.buildingChangeMode);
    commit('setNumberOfNonEditableBuildings', projectData.numberOfNonEditableBuildings);
    commit('setBuildingsCreated', projectData.buildingsCreated);

    if (transformedData.generalInformationCompleted) {
      commit('setGeneralInformationCompleted', true);
    }
    if (transformedData.propertyInformationCompleted) {
      commit('setPropertyInformationCompleted', true);
    }
    if (transformedData.propertyAssociationCompleted) {
      commit('setPropertyAssociationCompleted', true);
    }
    if (transformedData.buildingsImportCompleted) {
      commit('setBuildingsImportCompleted', true);
    }
  },
  // Step 1
  async setGeneralInformationCompleted({ commit }, completed: boolean) {
    commit('setGeneralInformationCompleted', completed);
  },
  async saveNewProjectData({ commit }, newProjectData: NewProjectData) {
    commit('setNewProjectData', newProjectData);
  },
  async saveNewBuildingData({ commit }, buildings: ProjectBuilding[]) {
    commit('setNewBuildingsData', buildings);
    updateBuildingChangeListByIds(
      state,
      buildings.map((building) => building.buildingId),
    );
  },
  async setActiveStep1BuildingId({ commit }, buildingId: number) {
    commit('setActiveStep1BuildingId', buildingId);
  },
  async setBuildingsImportCompleted({ commit }, completed: boolean) {
    commit('setBuildingsImportCompleted', completed);
  },
  async getNextActiveBuilding({ commit }) {
    const buildings = state.newProjectData.buildings;
    let found = false;
    for (let i = 0; i < buildings.length; i++) {
      // If no active building is set, set the first liegenshaft building as active
      if (
        (state.activeStep1BuildingId === null || state.activeStep1BuildingId === undefined) &&
        buildings[i].liegenshaft
      ) {
        commit('setActiveStep1BuildingId', buildings[i].buildingId);
        return;
      }
      if (buildings[i].buildingId === state.activeStep1BuildingId) {
        found = true;
        continue;
      }
      if (buildings[i].liegenshaft && found) {
        commit('setActiveStep1BuildingId', buildings[i].buildingId);
        return;
      }
    }
    // If no active building is found, set the first liegenshaft building as active
    for (let i = 0; i < buildings.length; i++) {
      if (buildings[i].liegenshaft) {
        commit('setActiveStep1BuildingId', buildings[i].buildingId);
        return;
      }
    }
  },
  async saveBuildingInformation(
    { commit, dispatch },
    {
      buildingInformation,
      apolloClient,
    }: {
      buildingInformation: ProjectBuilding;
      apolloClient: DollarApollo<Vue>;
      liegenshaftInformationCompleted: boolean;
    },
  ) {
    const buildings = state.newProjectData.buildings;
    // Get all building ids with corresponding valid indexes in displayPropertyInformationCheckboxes
    const selectedBuildingIds = [];
    for (let j = 0; j < buildings.length; j++) {
      // Check if index of building id is valid
      if (state.selectedBuildingsForContactInformation[buildings[j].buildingId]) {
        selectedBuildingIds.push(buildings[j].buildingId);
      }
    }
    // Add active building id to selectedBuildingIds
    if (state.activeStep1BuildingId) {
      selectedBuildingIds.push(state.activeStep1BuildingId);
    }
    // This will set the information for the active building and all those selected
    for (let i = 0; i < selectedBuildingIds.length; i++) {
      commit('setBuildingInformationForBuildingId', { buildingInformation, buildingId: selectedBuildingIds[i] });
    }
    if (state.activeStep1BuildingId) {
      for (let i = 0; i < buildings.length; i++) {
        // If the buildingId is not in buildingIds
        if (
          buildings[i].liegenshaft &&
          !selectedBuildingIds.includes(buildings[i].buildingId) &&
          !buildings[i].liegenshaftInformationCompleted
        ) {
          commit('setActiveStep1BuildingId', buildings[i].buildingId);
          commit('setDisplayPropertyInformationCheckboxes', false);

          return;
        }
      }
    }
    commit('setPropertyInformationCompleted', true);
    await dispatch('setUpdateTab', 'step2');
    await dispatch('saveToDB', { apolloClient, finalSave: false });
  },
  async updateAllBuildingInformation({ commit, dispatch }, buildingInformation: ProjectBuilding) {
    commit('setAllBuildingInformation', buildingInformation);
    commit('setPropertyInformationCompleted', true);

    await dispatch('setUpdateTab', 'step2');
  },
  // Step 2
  async setActiveStep2BuildingId({ state, commit }, id: number) {
    const idKey = `${id}`;

    if (state.buildingChangeMode) {
      // only bavigate if the building is editable
      if (state.editableBuildings?.has(idKey)) {
        commit('setActiveStep2BuildingId', id);
      }
    } else {
      commit('setActiveStep2BuildingId', id);
    }
  },
  async saveBuildingAssociations({ commit }, updatedBuilding: ProjectBuilding) {
    commit('saveBuildingAssociations', updatedBuilding);
  },
  async getNextActiveBuildingForStep2({ commit, dispatch }, apolloClient: DollarApollo<Vue>) {
    const buildings = state.newProjectData.buildings;
    let found = false;
    for (let i = 0; i < buildings.length; i++) {
      if (buildings[i].buildingId === state.activeStep2BuildingId) {
        found = true;
        continue;
      }
      if (buildings[i].liegenshaft && found) {
        commit('setActiveStep2BuildingId', buildings[i].buildingId);
        return;
      }
    }

    const liegenshafts = buildings.filter((building) => building.liegenshaft);

    // if we are on the last liegenschaft, move to the first one
    if (liegenshafts[liegenshafts.length - 1].buildingId === state.activeStep2BuildingId) {
      commit('setActiveStep2BuildingId', liegenshafts[0].buildingId);
    }
    // Set property association completed to true
    commit('setPropertyAssociationCompleted', true);
    // We for now, navigate to the next tab if no more liegenshaft buildings are found
    commit('setTab', 'step3');
    await dispatch('saveToDB', { apolloClient, finalSave: false });
  },
  async setPropertyAssociationCompleted({ commit }, completed: boolean) {
    commit('setPropertyAssociationCompleted', completed);
  },
  async assignActiveBuildingWIENumber({ commit }, wieNumber: string) {
    commit('updateBuildingWIENumber', { wieNumber, buildingId: state.activeStep2BuildingId });
  },
};

// Getters
const getters: GetterTree<ProjectCreateState, RootStore> = {
  currentTab: (state) => state.tab,
  newProjectData: (state) => state.newProjectData,
  activeStep1BuildingId: (state) => state.activeStep1BuildingId,
  activeBuilding: (state) =>
    state.newProjectData.buildings.find((building) => building.buildingId === state.activeStep2BuildingId),
  liegenschaftenWithEmptyConfigurations: (): ProjectBuilding[] =>
    state.newProjectData.buildings.filter((building) => building.liegenshaft && !buildingHasConfigurations(building)),
  buildingListForStep1InformationCopy: (state) => {
    return state.newProjectData.buildings.filter((building: ProjectBuilding) => {
      return building.liegenshaft && building.buildingId !== state.activeStep1BuildingId;
    });
  },
};

// Export the module
export const EnterpriseProjectCreateModule: Module<ProjectCreateState, any> = {
  state,
  mutations,
  actions,
  getters,
};
