import { PRODUCTION } from '@/env';
import { ToastMessage } from '@/features/core/components/toaster/model';
import delay from '@/util/delay';
import { get } from 'lodash';
import { Route } from 'vue-router';
import { ModuleTree, Store } from 'vuex';
import { App } from '../model';
import { AppCustomerPushCustomer } from './app-customer';
import { TECHNICIAN_ROLE } from './const';
import { IdToken } from './id-token';
import { AddToastMessageParams, TOAST_DEFAULT_DURATION } from './toast';
import {
  EnterpriseProjectCreateModule,
  ProjectCreateState,
} from '@/features/core/store/page-modules/enterprise-project-create/enterpriseProjectCreateModule';
import { EnterprisePropertyEditModule } from '@/features/core/store/page-modules/enterprise-project-details/enterprise-property-edit-module';
import { HeatingSystemsMetadataModule } from '@/features/core/store/page-modules/heating-systems-metadata/heating-systems-metadata.module';

export type RootStore = Store<RootState>;
export const SET_ID_TOKEN = 'SET_ID_TOKEN';
export const SET_REALM_ROLES = 'SET_REALM_ROLES';
export const SET_APP_CUSTOMER_CUSTOMER_ID = 'SET_APP_CUSTOMER_CUSTOMER_ID';
export const APPEND_TOAST_MESSAGES = 'APPEND_TOAST_MESSAGES';
export const REMOVE_TOAST_MESSAGES = 'REMOVE_TOAST_MESSAGES';
export const ADD_TOAST_MESSAGES = 'ADD_TOAST_MESSAGES';

export class RootState {
  public production = PRODUCTION;
  public title = 'IoT Client';
  public declare route?: Route;
  public idToken?: IdToken;
  public realmRoles?: string[];
  public toastMessages: ToastMessage[] = [];
  public appCustomerLastCustomerId?: string = undefined;
}

export async function createStore(apps: Record<string, App>): Promise<RootStore> {
  // Define modules
  const modules: ModuleTree<ProjectCreateState> = {
    EnterpriseProjectCreateModule,
    EnterprisePropertyEditModule,
    HeatingSystemsMetadataModule,
  };
  return new Store({
    state: () => new RootState(),
    getters: {
      appMap(): Record<string, App> {
        return apps;
      },
      app(state): App | undefined {
        return apps[get(state, 'route.meta.app', '') as string];
      },
      activeTreeNodeId(state, { app }: { app?: App }): string | undefined {
        return state.route === undefined ? undefined : app?.getActiveTreeNodeId?.(state.route);
      },
      admin(state): boolean {
        return state.idToken?.admin === true;
      },
      technician(state): boolean {
        if (!state.realmRoles) {
          return false;
        }
        return state.realmRoles.find((role) => role === TECHNICIAN_ROLE) !== undefined;
      },
      userId(state): string | undefined {
        return state.idToken?.sub;
      },
    },
    mutations: {
      [SET_ID_TOKEN](state, idToken?: IdToken) {
        state.idToken = idToken;
      },
      [APPEND_TOAST_MESSAGES](state, messages: ToastMessage[]) {
        state.toastMessages = state.toastMessages.concat(messages);
      },
      [REMOVE_TOAST_MESSAGES](state, messages: ToastMessage[]) {
        state.toastMessages = state.toastMessages.filter((message) => !messages.includes(message));
      },
      [SET_APP_CUSTOMER_CUSTOMER_ID](state, id: string) {
        state.appCustomerLastCustomerId = id;
      },
      [SET_REALM_ROLES](state, realmRoles?: string[]) {
        state.realmRoles = realmRoles;
      },
    },
    actions: {
      async [ADD_TOAST_MESSAGES](context, params: AddToastMessageParams) {
        context.commit(APPEND_TOAST_MESSAGES, params.messages);
        await delay(params.duration ?? TOAST_DEFAULT_DURATION);
        context.commit(REMOVE_TOAST_MESSAGES, params.messages);
      },
      async appCustomerPushCustomer(context, { customerId }: AppCustomerPushCustomer) {
        context.commit(SET_APP_CUSTOMER_CUSTOMER_ID, customerId);
      },
    },
    modules,
  });
}
